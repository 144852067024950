import { Box, Center, Flex, HStack, Image, Link, Spacer, Text } from '@chakra-ui/react'
import { AppContainer } from 'components/ui/appContainer'
import { CustomHeading } from 'components/ui/customHeading'
import { NewsListItem } from 'components/ui/newsListItem'
import { SearchHotelForm } from 'components/ui/searchHotelForm'
import { NextLink } from 'components/wrapper/nextLink'
import { useTopQuery } from 'generated/graphql'
import { SearchHotelFormInput } from 'helper/searchHotelParams'
import { SearchHotelQuery } from 'lib/query/searchHotelQuery'
import { useRouter } from 'next/router'
import { FC, useCallback, useState } from 'react'
import { Routes } from 'routes'

export const TopPage: FC = () => {
  const router = useRouter()
  const [searching, setSearching] = useState(false)

  const { data } = useTopQuery({ variables: { newsPagination: { limit: 5 } } })

  const onSubmit = useCallback(async (data: SearchHotelFormInput) => {
    setSearching(true)
    router.push({
      pathname: Routes.searchHotels(),
      query: SearchHotelQuery.build({ ...data, sortField: 'recommend', sortDirection: 'desc' }),
    })
  }, [])

  return (
    <>
      <AppContainer>
        <Box py={12}>
          <SearchHotelForm onSubmit={onSubmit} submitting={searching} />
        </Box>

        {data && data?.newsList.nodes.length > 0 && (
          <>
            <Spacer mt="100px" />
            <Center>
              <CustomHeading as="h3" underline>
                お知らせ
              </CustomHeading>
            </Center>

            <Spacer mt="8" />
            <Box borderColor="gray.100" borderWidth={1} rounded={20} px={8} py={4}>
              {data.newsList.nodes.map(news => (
                <NewsListItem key={news.id} news={news} />
              ))}
            </Box>
            <Flex direction="column" align="flex-end" mt={5}>
              <Link as={NextLink} href={Routes.news()}>
                <HStack spacing={2}>
                  <Image src="/assets/arrow_right_circle.svg" boxSize={4} />
                  <Text lineHeight={1}>過去のお知らせをすべて見る</Text>
                </HStack>
              </Link>
            </Flex>
          </>
        )}
      </AppContainer>
    </>
  )
}
