import { TopPage } from 'components/pages/top'
import { LoginRequired } from 'components/ui/loginRequired'
import { publicEnv } from 'env'
import { NextPage } from 'next'
import { NextSeo } from 'next-seo'

const Page: NextPage = () => {
  return (
    <>
      <NextSeo title={'ホーム | HoteCo'} noindex={true} openGraph={{ url: `${publicEnv.host}` }} />
      <LoginRequired requirements="registrationApproved">
        <TopPage />
      </LoginRequired>
    </>
  )
}

export default Page
