import { HStack, Text } from '@chakra-ui/react'
import { NextLink } from 'components/wrapper/nextLink'
import { NewsListItemFragment } from 'generated/graphql'
import { formatDate } from 'helper/dateFormat'
import { FC } from 'react'
import { Routes } from 'routes'

export type NewsListItemProps = {
  news: NewsListItemFragment
}

export const NewsListItem: FC<NewsListItemProps> = ({ news }) => {
  return (
    <NextLink href={Routes.newsDetail(news.id)}>
      <HStack
        spacing={5}
        px={2}
        py={4}
        borderBottomWidth={1}
        borderBottomColor="gray.500"
        borderBottomStyle="dashed"
        cursor="pointer"
        _last={{ borderBottom: 'none' }}
        _hover={{ backgroundColor: 'gray.20' }}
      >
        <Text fontSize={14} fontWeight="medium">
          {formatDate(new Date(news.releaseDate), 'yyyy/MM/dd')}
        </Text>
        <Text fontSize={14} fontWeight="medium" backgroundColor="#E9F0F8" py={1} px={4} rounded="full">
          {news.category}
        </Text>
        <Text>{news.title}</Text>
      </HStack>
    </NextLink>
  )
}
